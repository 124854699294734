import { Link } from "@StarberryUtils"
import React, { useEffect, useState } from "react"
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform";
import Slider from 'react-slick';
import FsLightbox from 'fslightbox-react';
import './FeaturedPropery.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from 'axios';
import { numberWithCommas } from "@Components/common/common-functions.js"
import { DRENT_PAGE_URL, DSALE_PAGE_URL } from "@Components/common/site/constants";
import { useLocation } from "@reach/router";
const FeaturedPropery = (props) => {
  const [salespropItems, salessetPropItems] = useState([])
  const [rentpropItems, rentsetPropItems] = useState([])
  const [ippropItems, ipsetPropItems] = useState([])
  const [tabTitles] = useState({
    tab1: 'Property for Sale in '+props.area,
    tab2: 'Property to Rent in '+props.area,
    tab3: 'International Property'
  });
  const [activeTab, setActiveTab] = useState("sale");
  const location = useLocation();
  const thePath  = location.pathname
  const strapiconfig = {
    headers: {
        Authorization:
            `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  }
  const getitemssales = async url => {
    try {
      const { data } = await axios.get(url, strapiconfig);
      salessetPropItems(data)
      
    
    } catch (error) {
    }
  }
  const getitemsrent = async url => {
    try {
      const { data } = await axios.get(url, strapiconfig);
      rentsetPropItems(data)
    } catch (error) {
      
    }
  }
  const getitemsinternationalprops = async url => {
    try {
      const { data } = await axios.get(url, strapiconfig);
      ipsetPropItems(data)
      console.log(data,'data')
    } catch (error) {
      
    }
  }
  const [lightboxController] = useState({
    toggler: false,
    slide: 1
});
const [imagesArr]  =useState([])


  useEffect(() => {
    let area = props.area ? props.area : "";
    let saleurl = process.env.GATSBY_STRAPI_SRC + "/properties?search_type=sales&_limit=3&_sort=price:DESC&publish=true"; // TODO: base URL supposed to be from .env
    let renturl = process.env.GATSBY_STRAPI_SRC + "/properties?search_type=lettings&_limit=3&_sort=price:DESC&publish=true"; // TODO: base URL supposed to be from .env
    let ipurl = process.env.GATSBY_STRAPI_SRC + "/properties?department=international&_limit=3&_sort=price:DESC&publish=true"; // TODO: base URL supposed to be from .env
    if (area) {
      saleurl = saleurl + "&display_address_contains=" + area;
      renturl = renturl + "&display_address_contains=" + area;
      //ipurl = ipurl + "&display_address_contains=" + area;
    }

    getitemssales(saleurl)
    getitemsrent(renturl)
    getitemsinternationalprops(ipurl)
  }, [])


  const settings = {
    dots: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick"
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  }

  return (
    salespropItems && salespropItems.length > 0 || rentpropItems && rentpropItems.length > 0 ?
    <div className="case-studies featured-properties">
      <Container>
        <Row>
          <Col xs>
            <div className="case-studies-block">
              <div className="case-studies-content">
                {/* <h2 className="d-lg-none">{props.Title}</h2> */}
                <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                  <h2 className="d-block">{props.Title}</h2>
                  {activeTab === "sale" && thePath == "/" &&(
                  <p>
                    We offer a wide selection of{' '}
                    <a href="/property/for-sale/in-manchester/">
                      <b>properties for sale in Manchester</b>
                    </a>. To make finding your dream home easier, you can filter and sort the listings. If you have any questions about buying a property, don't hesitate to contact Bentley Hurst, your trusted local estate agent in Manchester.
                  </p>
                )}
                {activeTab === "rent" && thePath == "/" &&(
                  <p>
                    We offer a wide selection of{' '}
                    <a href="/property/for-sale/in-manchester/">
                      <b>rental properties in Manchester</b>
                    </a>. You can easily filter and sort the listings to find your ideal home. If you have any questions about renting, feel free to contact Bentley Hurst, your local letting agent in Manchester.
                  </p>
                )}
                {activeTab === "international" && thePath == "/" &&(
                  <p>
                    We offer a wide selection of{' '}
                    <a href="/international-properties/ ">
                      <b>International Properties</b>
                    </a>. You can easily filter and sort the listings to find your ideal home. If you have any questions, feel free to contact Bentley Hurst.
                  </p>
                )}
                </ScrollAnimation>
              </div>
              <div className="case-studies-list slick-pagination">
                <Tabs 
                  activeKey={activeTab} 
                  onSelect={(key) => setActiveTab(key)}
                >
                  {salespropItems && salespropItems.length > 0 ?
                    <Tab eventKey="sale" title={tabTitles.tab1}>
                      <Slider className="feature-prop" {...settings} >
                        {salespropItems && salespropItems.length > 0 ? salespropItems.map((hit, ind) => {
                          let processedImages = JSON.stringify({});
                                if (hit?.imagetransforms?.images_Transforms) {
                                  processedImages = hit.imagetransforms.images_Transforms;
                                }
                          
                          return (
                            <div className=" case-studies-news img-zoom">
                                                            <FsLightbox
                        toggler={lightboxController.toggler}
                        sources={imagesArr}
                        key={lightboxController.key}
                        type='image'
                    />
                    {/* {JSON.stringify(hit)} */}
                              <div className="case-studies-img">
                              <Link to={DSALE_PAGE_URL.alias+'/'+hit.slug+'-'+hit.id}>
                                  <ImageTransform imagesources={hit.images[0].url} renderer="srcSet"
                                  imagename='property.images.similarpropertytile'
                                  attr={{ alt: hit?.title, className: '' }}
                                  imagetransformresult={processedImages} id={hit?.id} />

                                {/* <img src={hit.images[0].url} alt={hit.title} /> */}
                                
                              </Link>



                                <div className="on-map d-flex">
                                  {/* <Link to="#" onClick={() => openLighbox(ind, hit.images)}><i className="icon-camera"></i><span> {hit.images.length}</span></Link> */}
                                  {/* <Link to="#"><i className="icon-play-btn"></i> <span> 1</span></Link> */}
                                </div>
                              </div>
                              <div className="case-studies-title">
                                <div className="detail-head d-flex align-items-center justify-content-between">
                                  <p className="price">&pound;{numberWithCommas(hit.price)}</p>
                                  <Link className="wishlist-icn" to="#"><i className="icon-heart"></i></Link>
                                </div>
                                <h3><Link to={DSALE_PAGE_URL.alias+'/'+hit.slug+'-'+hit.id}>{hit.title}</Link></h3>
                                <span className="add-txt">{hit.display_address}</span>
                                {/* <span className="distance d-flex align-items-center"><i className="icon-station"></i>Oxford Road (1.2 miles)</span> */}

                              </div>

                            </div>
                          )
                        }) : ''}
                      </Slider>
                    </Tab>
                    : ''
                  }
                  {rentpropItems && rentpropItems.length > 0 ?
                    <Tab eventKey="rent" title={tabTitles.tab2}>
                      <Slider {...settings} >
                        {rentpropItems && rentpropItems.length > 0 ? rentpropItems.map((hit, ind) => {
                          let processedImages = JSON.stringify({});
                          if (hit?.imagetransforms?.images_Transforms) {
                            processedImages = hit.imagetransforms.images_Transforms;
                          }
                             
                          return (
                            <div className="case-studies-news img-zoom">
                                         <FsLightbox
                        toggler={lightboxController.toggler}
                        sources={imagesArr}
                        key={lightboxController.key}
                        type='image'
                    />
                              <div className="case-studies-img">
                                {/* <img src={hit.images[0].url} alt={hit.title} /> */}
                                <Link to={DRENT_PAGE_URL.alias+'/'+hit.slug+'-'+hit.id}>
                                  <ImageTransform imagesources={hit.images[0].url} renderer="srcSet"
                                  imagename='property.images.similarpropertytile'
                                  attr={{ alt: hit?.title, className: '' }}
                                  imagetransformresult={processedImages} id={hit?.id} />
                                
                              </Link>

                                <div className="on-map d-flex">
                                  {/* <Link to="#" onClick={() => openLighbox(ind, hit.images)}><i className="icon-camera"></i><span> {hit.images.length}</span></Link> */}
                                  {/* <Link to="#"><i className="icon-play-btn"></i> <span> 1</span></Link> */}
                                </div>
                              </div>
                              <div className="case-studies-title">
                                <div className="detail-head d-flex align-items-center justify-content-between">
                                  <h2 className="price">&pound;{numberWithCommas(hit.price.toFixed())}</h2>
                                  <Link className="wishlist-icn" to="#"><i className="icon-heart"></i></Link>
                                </div>
                                <h3><Link to={DRENT_PAGE_URL.alias+'/'+hit.slug+'-'+hit.id}>{hit.title}</Link></h3>
                                <span className="add-txt">{hit.display_address}</span>
                                {/* <span className="distance d-flex align-items-center"><i className="icon-station"></i>Oxford Road (1.2 miles)</span> */}

                              </div>
                            </div>
                          )
                        }) : ''}


                      </Slider>
                    </Tab> : ''}
                    {ippropItems && thePath == "/" && ippropItems.length > 0 ?
                    <Tab eventKey="international" title={tabTitles.tab3}>
                      <Slider {...settings} >
                        {ippropItems && ippropItems.length > 0 ? ippropItems.map((hit, ind) => {
                          let processedImages = JSON.stringify({});
                          if (hit?.imagetransforms?.images_Transforms) {
                            processedImages = hit.imagetransforms.images_Transforms;
                          }                             
                          return (
                            <div className="case-studies-news img-zoom">
                                  <FsLightbox
                                          toggler={lightboxController.toggler}
                                          sources={imagesArr}
                                          key={lightboxController.key}
                                          type='image'
                                  />
                              <div className="case-studies-img">
                                {/* <img src={hit.images[0].url} alt={hit.title} /> */}
                                <Link to={DRENT_PAGE_URL.alias+'/'+hit.slug+'-'+hit.id}>
                                  <ImageTransform imagesources={hit.images[0].url} renderer="srcSet"
                                  imagename='property.images.similarpropertytile'
                                  attr={{ alt: hit?.title, className: '' }}
                                  imagetransformresult={processedImages} id={hit?.id} />
                                
                              </Link>

                                <div className="on-map d-flex">
                                  {/* <Link to="#" onClick={() => openLighbox(ind, hit.images)}><i className="icon-camera"></i><span> {hit.images.length}</span></Link> */}
                                  {/* <Link to="#"><i className="icon-play-btn"></i> <span> 1</span></Link> */}
                                </div>
                              </div>
                              <div className="case-studies-title">
                                <div className="detail-head d-flex align-items-center justify-content-between">
                                  <h2 className="price">&pound;{numberWithCommas(hit.price.toFixed())}</h2>
                                  <Link className="wishlist-icn" to="#"><i className="icon-heart"></i></Link>
                                </div>
                                <h3><Link to={DRENT_PAGE_URL.alias+'/'+hit.slug+'-'+hit.id}>{hit.title}</Link></h3>
                                <span className="add-txt">{hit.display_address}</span>
                                {/* <span className="distance d-flex align-items-center"><i className="icon-station"></i>Oxford Road (1.2 miles)</span> */}

                              </div>
                            </div>
                          )
                        }) : ''}


                      </Slider>
                    </Tab> : ''}
                </Tabs>
              </div>



            </div>

          </Col>
        </Row>
      </Container>
    </div>
  : null)
}



export default FeaturedPropery